import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
// ie 11 did not have TextEncoder/TextDecoder
//import {TextEncoder, TextDecoder} from "fastestsmallesttextencoderdecoder"; // ie 11: Object doesn't support this action... new TextEncoder().encode
//import * as f from "fastestsmallesttextencoderdecoder"; // gives encode is not a function...
// ie 11 did not have fetch
import fetchPonyfill from 'fetch-ponyfill';
const {fetch /*, Request, Response, Headers*/} = fetchPonyfill({});
//const {encode, decode} = require("fastestsmallesttextencoderdecoder");
//const {TextEncoder, TextDecoder} = require("fastestsmallesttextencoderdecoder");
//const encode = (TextEncoder).encode;
//const decode = (TextDecoder).decode;
function encode(x: any) {return x;}
function decode(x: any) {return x;}

async function fetchValue(): Promise<string> {
  let url = 'https://httpbin.org/uuid'
  return await (await fetch(url)).text();
}

async function fetchWebsocketValue(): Promise<string> {
  return new Promise((resolve, reject) => {
    const ws = new WebSocket("wss://ws.vi-server.org/mirror/");
    ws.binaryType = "arraybuffer";

    ws.onopen = function () {
        console.log("onopen");
        ws.send(encode("websocket echo test"))
    };

    ws.onmessage = async function (evt) {
        console.log("onmessage", {evt});
        const data = evt.data //await evt.data.arrayBuffer();
        console.log({data})
        const text = decode(data)
        resolve(text)
    };

    ws.onclose = function (ev) {
        console.log("onclose");
        reject("onclose")
    };

    ws.onerror = function (ev) {
        console.log("onerror", ev);
        reject("onerror")
    };
    
  });
}

function App() {

  const [value, setValue] = useState("-not fetched yet-");
  const [value2, setValue2] = useState(0);
  const [value3, setValue3] = useState("-not fetched yet-");

  useEffect(() => {
    fetchValue().then(x => setValue(x));
    fetchWebsocketValue().then(x => setValue3(x));
    let i = 0;
    setInterval(() => setValue2(++i), 1000);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload. Response from backend: {value}. Time: {value2}. Response from echo WebSocket: {value3}.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
